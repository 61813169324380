import React from "react"
import { Link } from "gatsby"
import { css } from "@emotion/core"

import PageLayout from "../components/PageLayout"
import SEO from "../components/seo"

import { colors } from "css/theme"
import { h2, btn } from "css/primitives"

const wrapper = css`
  margin-top: -90px;
  background-color: ${colors.background};
  background-image: linear-gradient(to bottom, black, ${colors.background} 19%);
  padding-top: 165px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding-bottom: 130px;
  padding-left: 1rem;
  padding-right: 1rem;
  @media (max-width: 479px) {
    padding-top: 130px;
    padding-bottom: 75px;
  }
`

const h1CSS = [
  h2,
  css`
    color: ${colors.orange};
    font-size: 200px;
    line-height: 1;
    font-weight: bold;
    margin-bottom: 1.5rem;
    @media (max-width: 767px) {
      font-size: 80px;
    }
  `,
]

const h2CSS = [
  h2,
  css`
    font-size: 42px;
    line-height: 1.24;
    color: #d0d0d0;
    margin-bottom: 2rem;
    font-weight: bold;
    max-width: 524px;
    margin-right: auto;
    margin-left: auto;
    @media (max-width: 479px) {
      font-size: 1.5rem;
      margin-bottom: 1rem;
      line-height: 1.52;
    }
  `,
]

const description = css`
  max-width: 640px;
  margin-right: auto;
  margin-left: auto;
  color: white;
  opacity: 0.75;
  font-size: 21px;
  line-height: 1.52;
  margin-bottom: 3rem;
  @media (max-width: 479px) {
    font-size: 18px;
  }
`

const button = [
  btn,
  css`
    background-color: ${colors.orange};
    color: white;
    letter-spacing: 1px;
    line-height: 2.25;
    padding: 10px 2rem;
    display: inline-block;
    position: relative;
    z-index: 1;
    width: 100%;
    max-width: 342px;
    &:hover {
      color: white;
    }
  `,
]

const NotFoundPage = () => (
  <PageLayout options={{ headingBackground: false }}>
    <SEO title="404" />
    <div css={wrapper}>
      <h1 css={h1CSS}>404</h1>
      <h2 css={h2CSS}>Looks Like We Have a False Positive</h2>
      <p css={description}>
        The page you’re looking for doesn’t exist. Just because you took the
        path less travelled, doesn’t mean that you’re lost
      </p>
      <Link to="/" css={button}>
        Go to Homepage
      </Link>
    </div>
  </PageLayout>
)

export default NotFoundPage
